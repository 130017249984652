import { useStaticQuery, graphql } from "gatsby"

export const useConfig = () => {
  const { site } = useStaticQuery(
    graphql`
      query ConfigQuery {
        site {
          siteMetadata {
            siteUrl
            title
            facebook_app_id
            google_clent_id
          }
        }
      }
    `
  )


  return site.siteMetadata
}
