import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage, useFormikContext } from "formik";
import { string } from "../../util/strings";
import { Link } from "gatsby";
import CloseBtn from "../../images/close.svg";

// A custom validation function. This must return an object
// which keys are symmetrical to our values/initialValues
const validate = (values) => {
  
  const errors = {};

  const required = string("form.required");

  if (!values.password) {
    errors.password = required;
  }

  if (!values.password2) {
    errors.password2 = required;
  }

  if (
    values.password &&
    values.password2 &&
    values.password !== values.password2
  ) {
    errors.password2 = string("register.password.not match");
  }

  if (!values.email) {
    errors.email = required;
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = string("form.Invalid email address");
  }

  if (!values.name) {
    errors.name = required;
  }

  if (!values.phone) {
    errors.phone = required;
  }

  if (!values.aggrement) {
    errors.aggrement = string("register.aggrement.not confiremed");
  }

  if (!values.title) {
    errors.title = required;
  }

  if (values.title === "אחר") {
    if (!values.title2) {
      errors.title2 = required;
    }
  }

  //console.log(values, errors)
  return errors;
};

const RegisterForm = ({ onSubmit, loading }) => {

  const [takanon, setTakanon] = useState(false);
  
  return (
    <div className="">
      <Formik
        initialValues={{
          name: "",
          email: "",
          phone: "",
          title: "",
          password: "",
          password2: "",
          aggrement: false,
          newsletter: false,
        }}
        validate={validate}
        onSubmit={async (values) => {
          onSubmit(values);
        }}
      >
        {({ isValid, errors }) => (
          <Form className="space-y-6">
            <div className="flex flex-col lg:flex-row justify-between lg:space-x-8 lg:space-x-reverse space-y-6 lg:space-y-0">
              <FormField id="name" placeholder="שם מלא" />
              <FormField id="phone" placeholder="נייד" />
            </div>

            <div className="flex flex-col lg:flex-row justify-between lg:space-x-8 lg:space-x-reverse space-y-6 lg:space-y-0">
              <FormField id="email" placeholder="אימייל" type="email" />
              {/* <FormField id="title" placeholder="תפקיד" /> */}

              <MyField />
            </div>

            <div className="flex flex-col lg:flex-row justify-between lg:space-x-8 lg:space-x-reverse space-y-6 lg:space-y-0">
              <FormField id="password" placeholder="סיסמה" type="password" />
              <FormField
                id="password2"
                placeholder="וידוא סיסמה"
                type="password"
              />
            </div>

            <div className="flex flex-col lg:flex-row justify-around">
              <FormCheckbox
                id="aggrement"
                label={
                  <div className="inline">
                    <button
                      className="text-blue underline cursor-pointer"
                      onClick={() => setTakanon(true)}
                    >
                      התקנון
                    </button>{" "}
                    מוסכם עליי
                  </div>
                }
              >
                {errors.aggrement ? (
                  <div className="error text-danger">{errors.aggrement}</div>
                ) : (
                  <div></div>
                )}
              </FormCheckbox>
              <FormCheckbox
                id="newsletter"
                label="אתם יכולים לשלוח לי עדכונים"
              />
            </div>

            <div className="text-center">
              <button
                type="submit"
                className="bg-navy hover:bg-blue transition font-bold text-white p-3 w-64"
                disabled={loading || !isValid}
              >
                הרשמה{" "}
                {loading && (
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
              </button>
            </div>

            <div>
              <div className="auth-mid text-center">
                <p>
                  {string("register.is registered")}
                  {" - "}
                  <Link className="text-blue" to={"/login"}>
                    {string("register.is registered login")}
                  </Link>
                </p>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <TakanonModal show={takanon} closeModal={() => setTakanon(false)} />
    </div>
  );
};

const MyField = () => {

  const [other, setOther] = useState(false);
  const {
    values: { title },
  } = useFormikContext();

  React.useEffect(() => {
    
    if (title === "אחר") {
      setOther(true);
    } else {
      setOther(false);
    }
  }, [title]);

  return (
    <div className="w-full">
      <FormSelect
        id="title"
        placeholder="תפקיד"
        options={["יזם עסקי", 'יזם נדל"ן', "בעל עסק", "שמאי", "אדריכל", "אחר"]}
      />
      {other && <FormField id="title2" placeholder="תפקיד אחר" />}
    </div>
  );
};

const FormField = ({ id, type = "input", placeholder, ...props }) => {
  return (
    <div className="w-full">
      <label htmlFor={id} className="form-label hidden">
        {placeholder}
      </label>
      <Field
        id={id}
        name={id}
        type={type}
        className="form-control px-4 py-3 border border-grey2 placeholder:text-grey2 w-full"
        placeholder={placeholder}
      />
      <ErrorMessage name={id} component="div" className="error text-danger" />
    </div>
  );
};

const FormSelect = ({ id, type = "input", placeholder, ...props }) => {
  return (
    <div className="w-full">
      <label htmlFor={id} className="form-label hidden">
        {placeholder}
      </label>
      <Field
        as="select"
        id={id}
        name={id}
        type={type}
        className="form-control px-1 py-3 border border-grey2 placeholder:text-grey2 w-full"
        placeholder={placeholder}
      >
        <option value="">{"תפקיד"}</option>
        {props.options.map((op, index) => (
          <option key={index} value={op}>
            {op}
          </option>
        ))}
      </Field>
      <ErrorMessage name={id} component="div" className="error text-danger" />
    </div>
  );
};

const FormCheckbox = ({ id, type = "checkbox", label, children, ...props }) => {
  //console.log(props)
  return (
    <div>
      <label htmlFor={id} className="form-label">
        <Field
          id={id}
          name={id}
          type={type}
          className="form-control px-4 py-3 border border-grey2 placeholder:text-grey2"
          //placeholder={placeholder}
        />
        {"   "}
        {label}
      </label>
      {children}
      {id !== "aggrement" && (
        <ErrorMessage name={id} component="div" className="error text-danger" />
      )}
    </div>
  );
};

const TakanonModal = ({ show, closeModal }) => {
  return (
    <div
      className={
        "modal opacity-0  fixed w-full 	 h-full top-0 left-0 flex items-center justify-center " +
        (show ? " block opacity-100" : " hidden opacity-0")
      }
    >
      <div
        className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
        onClick={closeModal}
        onKeyDown={closeModal}
        role="presentation"
      ></div>

      <div
        className="modal-container bg-white w-11/12 lg:max-w-4xl	 -md:max-w-md mx-auto rounded shadow-lg  overflow-y-auto max-h-screen "
        style={{ zIndex: "150" }}
      >
        <div className="modal-content py-4 ">
          <div className="flex justify-between items-center pb-3 px-6">
            <button
              className="modal-close cursor-pointer z-50"
              onClick={closeModal}
            >
              {/* <svg className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
              </svg> */}
              <img src={CloseBtn} alt="close button" />
            </button>
          </div>

          <div className="space-y-3 px-14 lg:px-28 pt-10 pb-10 lg:pb-24 text-justify text-xl 	">
            <h2 className="text-blue font-bold text-2xl">תקנון</h2>

            <p className="">
              אין להסתמך על המחשבונים המופיעים באתר הרובע הפתוח לצורך פעולות
              כלשהן או ביצוע עסקאות. מטרת המחשבונים היא להוות כלי עזר והנגשת
              מידע לאפשרויות תכנון עתידיות בהתאם לתכנית המדיניות החדשה באזור
              התעשייה הישן באילת והם אינם מהווים תיק מידע ו/או מידע רשמי. בסיס
              הנתונים של המחשבונים מתעדכן מידי תקופה ויכול שהנתונים לחישוב אינם
              מדויקים או מעודכנים.
            </p>

            <p>
              יש לאשר את המידע והפלט המתקבל במנהל ההנדסה בעיריית אילת טרם ביצוע
              פעולה כלשהי. אתר הרובע הפתוח, עיריית אילת והחברה הכלכלית לאילת
              (חכ"א) בע"מ אינם אחראיים ולא יהיו אחראיים באופן כלשהו לנזקים ו/או
              להפסדים העלולים להיגרם כתוצאה מהסתמכות על חישוב כלשהו שבוצע
              במחשבונים באתר בפרט, או על כל תוכן אחר המופיע באתר.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterForm;
