import React from "react"
import RegisterForm from "../RegisterForm";
import { useDispatch, useSelector } from 'react-redux'

const RegisterBlock = () => {

  const dispatch = useDispatch()
  const { submitting, messages } = useSelector(s => s.registration)

  const customOnSccess = (values) => {

    const title = values.title === 'אחר' ? values.title2 : values.title 

    const data = {
      mail: [{ "value": values.email }],
      name: [{ "value": values.name }],
      field_title: [{ "value": title }],
      field_phone: [{ "value": values.phone }],
      field_full_name: [{ "value": values.name }],
      field_send_update: [{ "value": values.newsletter }],
      pass: [{ "value": values.password }],
    }

    dispatch({
      type: 'registration/init',
      payload: data
    })

  }

  const reset = () => {
    dispatch({
      type: 'registration/reset',
      payload: {}
    })
  }

  return (
    <div>
      {messages.length > 0 && <div className="text-danger"><p>{messages[0]}</p></div>}
      <div>
        <RegisterForm onSubmit={customOnSccess} loading={submitting} />
        <button className="hidden" onClick={reset} disabled>Reset</button>
      </div>
    </div>
  );

}






export default RegisterBlock
