import React/* , { useState } */ from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
//import getMetatags from "../util/getMetatags"
import Seo from "../components/Seo"
//import useViewportWidth from "../hooks/useViewportWidth"

import RegistrationBlock from "../components/RegistrationBlock";
import RegistrationThirdParty from "../components/RegistrationThirdParty";

import { string } from "../util/strings";
import UserInteraction from "../components/user-interaction";


const IndexPage = ({ data, ...props }) => {

  const { location } = props
  const { search } = location
  const success = search === '?success'

  //console.log(success, search, location)

  const seo = { title: 'הרשמת משתמשים'}

  //const width = useViewportWidth()
  //const isMobile = width <= 768
  //console.log('width, isMobile = ', width, isMobile)


  return (
    <Layout>
      <Seo {...seo} />

      <section className=" mt-[90px] lg:mt-[126px] lg:pt-12 pt-4 pb-12  bg-gray-500">

        <UserInteraction
          title={string('register.title')}
          className="lg:bg-[url(/backgrounds/pattern4.svg)] bg-white"
        >
          {success && <Success />}
          {!success &&
            <>
              <div className="">
                <RegistrationThirdParty />
              </div>


              <div className=" text-center lg:py-4 flex flex-row items-center">
                <div className="grow h-px bg-yellow" />
                <div className="px-2" >או</div>
                <div className="grow  h-px bg-yellow" />
              </div>


              <div className=" text-center ">
                <RegistrationBlock />
              </div>
            </>
          }
        </UserInteraction>

      </section>


    </Layout >
  )
}

export default IndexPage

const Success = () => {

  return (
    <div className="space-y-2">
      <h2 className="text-2xl text-blue">{string('register.success.title')} </h2>
      <div>{string('register.success.message')}</div>
      <Link to={'/login'} className="bg-navy p-3 my-8 text-white  hover:bg-yellow hover:text-white block w-fit" >{string('register.success.button')}</Link>
    </div>
  )
}
/*
const Hero = ({ title, subtitle, bgImage, className, ...props }) => {

  return (
    <section
      className={' bg-no-repeat bg-center-top py-12 lg:py-28 lg:px-32  ' + className}
      style={{ backgroundImage: `linear-gradient(rgba(11, 11, 11, 0.5), rgba(11, 11, 11, 0.5)), url('${bgImage.publicURL}')` }}
    >

      <div className="mx-auto text-white text-center">
        <h1 className="text-[28px] lg:text-[115px] -text-blue">{title}</h1>
        <h2 className="text-base lg:text-[50px] -max-w-[600px] -border leading-tight	" dangerouslySetInnerHTML={{ __html: subtitle }} />
      </div>
    </section>
  )
}
 */



export const Head = () => <title>הרשמה</title>

export const query = graphql`
  query registerQuery {
    backgrounds: allFile(filter: {sourceInstanceName: {eq: "backgrounds"}}) {
      nodes {
        base
        publicURL
      }
    }
  }
`
